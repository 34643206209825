import { FC, useEffect, useState } from "react";
import {
  plus,
  minus,
  shield,
  coverage,
  group,
  rates,
  chart,
} from "../../common/svg";
import { Link, useNavigate } from "react-router-dom";

const Payment: FC = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initialize based on current width

  const [openSTSIndex, setOpenSTSIndex] = useState<number[]>([0, 1]);
  const [openFAQIndex, setOpenFAQIndex] = useState<{
    section: number | null;
    index: number | null;
  }>({ section: null, index: null });

  // Toggle function to handle opening/closing of the FAQs
  const toggleFAQ = (section: number, index: number) => {
    // If the same FAQ is clicked, close it; otherwise, open the new one
    if (openFAQIndex.section === section && openFAQIndex.index === index) {
      setOpenFAQIndex({ section: null, index: null });
    } else {
      setOpenFAQIndex({ section, index });
    }
  };

  const toggleSTS = (index: number) => {
    setOpenSTSIndex(
      (prevState) =>
        prevState.includes(index)
          ? prevState.filter((i) => i !== index) // Remove index if it exists
          : [...prevState, index] // Add index if it's not present
    );
  };

  const faqContent1 = [
    {
      question: "What is Shiga?",
      answer:
      "Shiga Digital is leading the way in creating a cutting-edge blockchain-based bank with a special focus on Pan Africa. Say goodbye to traditional finance and hello to the new era of financial empowerment for Pan-Africa! We're building the core components to redefine the financial landscape, providing liquidity through stablecoins, seamless payments, and access to real-world assets. Our self-custodial wallets will empower you to take control of your assets like never before. Shiga is paving the way for the next wave of enterprises and individuals seeking innovative financial services. Get ready to join the movement!",
    },
    {
      question: "Why did we build Shiga?",
      answer:
        "Blockchain-based finance has the potential to transform access to finance, with a handful of companies already leading this revolution. However, to make this work for the benefit of all and truly achieve scale, we need to fix the disconnect between the current system and the immaturity of the new system. Shiga is driving towards a future where both systems coexist harmoniously, bringing the best of both worlds to businesses, banks, and payment providers.",
    },
    {
      question: "Is Shiga regulated?",
      answer:
        "Shiga's VASP is registered in the Czech Republic, which enables Shiga to engage in digital asset services in Europe. Specifically, this includes the intermediation of trade and services, wholesale and retail trade, software provision, information technology consultancy, data processing, hosting and related activities, web portals, and virtual asset services.",
    },
    {
      question: "How is shiga regulated out of europe?",
      answer:
        "Outside of Europe, Shiga Digital, Inc. is a Delaware-based C Corporation Leveraging regulated entities to provision subsequent gateways in supported regions.",
    },
  ];

  const faqContent2 = [
    {
      question: "What exactly are stablecoins?",
      answer:
        "Stablecoins are digital currencies designed to provide stability. They are pegged to stable assets like the US dollar and backed by equivalent US Treasuries reserves. Issued by regulated entities such as Circle or Coinbase, stablecoins combine cryptocurrencies' advantages with traditional currencies' stability.",
    },
    {
      question: "What is a self-custody wallet? ",
      answer:
        " Non-custodial means that you're always in control. Unlike traditional banks, Shiga never has unilateral access to your funds. There’s no rehypothecation without your consent—your assets are yours. Our platform ensures complete transparency and control, setting a new financial freedom and security standard.",
    },
    {
      question: "How long does it take for transactions to process?",
      answer:
        "We aim to make every Shiga Powered transaction feel instant. Most portal transactions are processed in less than five minutes using your Shiga portals. Shiga uses blockchain technology to process all orders instantly (sending them for clearing immediately). Transaction times may vary depending on local banking processing times.",
    },
    {
      question: "Does Shiga have a mobile application?",
      answer:
        "Coming soon 😃",
    },
  ];


     // Detect if the screen is mobile and update state
     useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Update state if screen width is below 768px
      };
  
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

  return (
    <div className="business-wrapper relative">
      <div className={`business-hero relative flex items-start justify-between bg-white pt-12 pb-18 z-10 w-full ${isMobile ? "pt-[7.5rem] h-[578px] items-center" : "pt-12 h-[640px] items-start"} -mt-16`}>
        <div className={`business-cont ${isMobile ? "px-[24px] w-full -mt-[0.5rem] items-center" : "pl-32 w-[790px] mt-[9rem]"} absolute flex flex-col justify-center z-20`}>
          <p className={`font-ibm font-medium ${isMobile ? "text-[11px]" : "text-[10.5px]"} w-fit px-2 py-[0.5px] rounded-2xl text-horange-700 bg-horange-50 border border-horange-200`}>
            PAYMENTS
          </p>
          <h1 className={`text-htext-main ${isMobile ? "text-[31px] leading-[38px] text-center" : "text-[43px] leading-[58px]"} font-satoshi font-black mt-[24px]`}>
            Shiga STS: Tailored for High-Volume Trades
          </h1>
          <p className={`text-htext-label ${isMobile ? "text-[19px] text-center" : "text-[17.5px]"} font-inter font-normal mt-[24px]`}>
            Leverage our multichain and multicurrency rails for large-scale
            trades. Get personalized support from a dedicated account manager.
          </p>
          <div className={`mt-[40px] flex items-center ${isMobile ? "flex-col w-full" : ""}`}>
            <button onClick={() => navigate('/coming-soon')} className={`${isMobile ? "px-[1.1rem]" : "w-[275px]"} py-[9.5px] text-[14.5px] text-white font-ibm font-semibold rounded-[7px] border border-hbrand-600 bg-hbrand-600 hover:bg-hbrand-700 hover:border-hbrand-700 shadow-s-light`}>
              Get started using STC
            </button>
            <Link to="/case-study/accept-crypto-as-a-payment-method-without-knowing-anything-about-crypto">
              <p className={`text-hbrand-700 text-[14px] font-ibm font-bold ${isMobile ? "text-center mt-[2.3rem]" : "ml-[38px]"}`}>
                View case study
              </p>
            </Link>
          </div>
        </div>

        <div className={`${isMobile ? "hidden" : "flex"} w-[490px] absolute mt-[9.5rem] right-36 flex flex-col justify-center`}>
          <img
            className="w-[94%] relative z-20 m-auto"
            src="/assets/orange.svg"
            alt="transactions"
          />
        </div>
      </div>

      <div className="3steps relative bg-hbrand-25">
        <div className={`3steps-cont ${isMobile ? "px-[28px] pt-[55px] pb-[69px]" : "py-[104px] px-[148px]"} w-full flex flex-col items-center`}>
          <h2 className="differ-header font-satoshi font-black text-hgray-950 text-[43.5px] text-center">
            What is STS?
          </h2>

          <div className={`flex flex-col items-center justify-center ${isMobile ? "w-full" : "w-[680px]"} mt-[26px]`}>
            <p className="font-ibm font-normal text-htext-main text-[17.5px] text-center leading-7">
            With Shiga's Structured Transaction Services (STS), businesses and high-net-worth individuals can confidently trade large amounts of digital assets and fiat currencies outside traditional exchanges. Whether you're dealing in BTC, ETH, or other assets, we've got you covered for secure and reliable transactions.
            </p>

            <div className={`flex flex-col items-start justify-center ${isMobile ? "w-full" : "w-[77%]"} mt-[48px]`}>
              {[
                {
                  question: "Corporates and SME's",
                  answer:
                    "We provide blockchain-based financial solutions to assist enterprises in the face of a changing economic environment. Our key service is stablecoin provision in USD for liquidity, hedging and payments.",
                },
                {
                  question: "High-net-worth individual's (HNWI)",
                  answer:
                    "HNWI's looking to venture into the world of blockchain finance to purchase stablecoins for its various use cases or want to begin venturing into the world of blockchain based finance can leverage our expertise and rails to do so.",
                },
              ].map((sts, index) => (
                <div
                  key={index}
                  className="faq-card w-full border-t border-t-hgray-200 py-5"
                >
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() => toggleSTS(index)}
                  >
                    <h3 className="font-ibm font-bold text-[15.5px] text-htext-main">
                      {sts.question}
                    </h3>
                    <span
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: openSTSIndex.includes(index) ? minus : plus,
                      }}
                    />
                  </div>

                  {openSTSIndex.includes(index) && (
                    <div className="toggle-content mt-3">
                      <p className="font-ibm font-normal text-[14px] text-htext-label w-[95%]">
                        {sts.answer}
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="home-differ relative bg-[#12141D]">
        <div className={`built-cont ${isMobile ? "py-[70px] px-[24px]" : "py-[99px] px-[148px]"}`}>
          <h2 className={`differ-header font-satoshi font-black text-white text-[43.5px] text-center ${isMobile ? "leading-[38px]" : ""}`}>
          Key benefits of using Shiga’s STS
          </h2>

          <div className={`flex flex-col items-center justify-center ${isMobile ? "mt-[60px]" : "mt-[70px]"}`}>
            <div className={`flex w-full ${isMobile ? "items-center flex-wrap gap-y-12" : "items-start gap-x-[64px]"} justify-center`}>
              <div className={`col-cont ${isMobile ? "w-full flex flex-col items-center" : "w-[43.5%]"}`}>
                <div className="flex items-center">
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: group }}
                  />
                  <p className="ml-2 text-[16.5px] font-satoshi font-black text-white">
                  Personalised Support
                  </p>
                </div>

                <p className={`built-texts mt-5 text-[16.5px] font-ibm font-normal text-hgray-300 ${isMobile ? "text-center" : ""}`}>
                Work with a dedicated account manager to guide you through each trade.
                </p>
              </div>

              <div className={`col-cont ${isMobile ? "w-full flex flex-col items-center" : "w-[43.5%]"}`}>
                <div className="flex items-center">
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: rates }}
                  />
                  <p className="ml-2 text-[16.5px] font-satoshi font-black text-white">
                  Competitive Rates
                  </p>
                </div>

                <p className={`built-texts mt-5 text-[16px] font-ibm font-normal text-hgray-300 ${isMobile ? "text-center" : ""}`}>
                Get access to better pricing for large transactions, ensuring you maximize the value of every trade.
                </p>
              </div>
            </div>

            <div className={`flex w-full ${isMobile ? "items-center flex-wrap gap-y-12 mt-12" : "items-start gap-x-[64px] mt-[64px]"} justify-center`}>
              <div className={`col-cont ${isMobile ? "w-full flex flex-col items-center" : "w-[43.5%]"}`}>
                <div className="flex items-center">
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: coverage }}
                  />
                  <p className="ml-2 text-[16.5px] font-satoshi font-black text-white">
                  Global Access
                  </p>
                </div>

                <p className={`built-texts mt-5 text-[16.5px] font-ibm font-normal text-hgray-300 ${isMobile ? "text-center" : ""}`}>
                Trade across global markets with support for multiple fiat and stablecoin pairs.
                </p>
              </div>

              <div className={`col-cont ${isMobile ? "w-full flex flex-col items-center" : "w-[43.5%]"}`}>
                <div className="flex items-center">
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: shield }}
                  />
                  <p className="ml-2 text-[16.5px] font-satoshi font-black text-white">
                  Security and Compliance
                  </p>
                </div>

                <p className={`built-texts mt-5 text-[16.5px] font-ibm font-normal text-hgray-300 ${isMobile ? "text-center" : ""}`}>
                We prioritize security with full regulatory compliance to protect your funds and transactions.
                </p>
              </div>
            </div>

            <div className={`flex w-full ${isMobile ? "items-center flex-wrap gap-y-12 mt-12" : "items-start gap-x-[64px] mt-[64px]"} justify-center`}>
              <div className={`col-cont ${isMobile ? "w-full flex flex-col items-center" : "w-[43.5%]"}`}>
                <div className="flex items-center">
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: chart }}
                  />
                  <p className="ml-2 text-[16.5px] font-satoshi font-black text-white">
                  Industry Expertise
                  </p>
                </div>

                <p className={`built-texts mt-5 text-[16.5px] font-ibm font-normal text-hgray-300 ${isMobile ? "text-center" : ""}`}>
                Benefit from our deep experience in blockchain and financial markets.
                </p>
              </div>
              <div className={`col-cont ${isMobile ? "w-full flex flex-col items-center" : "w-[43.5%]"} bg-h-bg`}>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-faq relative bg-white">
        <div className={`faq-cont ${isMobile ? "px-[24px] pt-[85px] pb-[25rem]" : "pt-[88px] pb-[17rem] px-[148px]"}`}>
          <h2 className="faq-header font-satoshi font-black text-hgray-950 text-[43.5px] text-start">
            FAQ
          </h2>
          <div className={`w-full flex ${isMobile ? "flex-col" : ""} items-start justify-between flex-wrap mt-6`}>
            {/* First Column of FAQs */}
            <div className={`flex flex-col items-start justify-center ${isMobile ? "w-full" : "w-[48%]"}`}>
              {faqContent1.map((faq, index) => (
                <div
                  className={`faq-card w-full cursor-pointer ${
                    index === 0
                      ? "border-y border-y-hgray-200"
                      : "border-b border-b-hgray-200"
                  } py-5`}
                  key={index}
                  onClick={() => toggleFAQ(1, index)}
                >
                  <div className="flex items-center justify-between">
                    <h3 className="font-ibm font-bold text-[15.5px] text-htext-main">
                      {faq.question}
                    </h3>
                    <span
                      className=""
                      dangerouslySetInnerHTML={{
                        __html:
                          openFAQIndex.section === 1 &&
                          openFAQIndex.index === index
                            ? minus
                            : plus,
                      }}
                    />
                  </div>
                  {openFAQIndex.section === 1 &&
                    openFAQIndex.index === index && (
                      <div className="toggle-content mt-2.5">
                        <p className="font-ibm font-normal text-[14.5px] text-htext-label w-[90%]">
                          {faq.answer}
                        </p>
                      </div>
                    )}
                </div>
              ))}
            </div>

            {/* Second Column of FAQs */}
            <div className={`flex flex-col items-start justify-center ${isMobile ? "w-full" : "w-[48%]"}`}>
              {faqContent2.map((faq, index) => (
                <div
                  className={`faq-card w-full cursor-pointer ${
                    isMobile && index === 0
                      ? "border-b border-b-hgray-200" : !isMobile && index === 0 ?
                      "border-y border-y-hgray-200"
                      : "border-b border-b-hgray-200"
                  } py-5`}
                  key={index}
                  onClick={() => toggleFAQ(2, index)}
                >
                  <div className="flex items-center justify-between">
                    <h3 className="font-ibm font-bold text-[15.5px] text-htext-main">
                      {faq.question}
                    </h3>
                    <span
                      className=""
                      dangerouslySetInnerHTML={{
                        __html:
                          openFAQIndex.section === 2 &&
                          openFAQIndex.index === index
                            ? minus
                            : plus,
                      }}
                    />
                  </div>
                  {openFAQIndex.section === 2 &&
                    openFAQIndex.index === index && (
                      <div className="toggle-content mt-2.5">
                        <p className="font-ibm font-normal text-[14.5px] text-htext-label w-[90%]">
                          {faq.answer}
                        </p>
                      </div>
                    )}
                </div>
              ))}
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Payment;
