import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./utlis/index";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Home from "./components/home";
import Navbar from "./common/navbar";
import Footer from "./common/footer";
import Business from "./components/business";
import Portal from "./components/portal";
import Payment from "./components/payment";
import CaseStudyHome from "./components/case-studies";
import About from "./components/about";
import Contact from "./components/contact";
import CaseStudyDoBusiness from "./components/case-studies/do-business";
import CaseStudyHelpOthers from "./components/case-studies/help-others";
import CaseStudyEnableBusiness from "./components/case-studies/enables-business";
import ComingSoon from "./components/coming-soon";

function App() {
  return (
    <div className="App relative overflow-x-hidden">
     <Router>
     <ScrollToTop />
        <section className="navbar pl-0">
          <Navbar />
        </section>

        <section className="content">
          <Routes>
            {/* Define routes for different components */}
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/contact-support" element={<Contact />} />
            <Route path="/portal-in-and-out" element={<Portal />} />
            <Route path="/our-case-studies" element={<CaseStudyHome />} />
            <Route path="/shiga-otc-tailored-for-high-volume-trades" element={<Payment />} />
            <Route path="/blockchain-finance-for-global-enterprises" element={<Business />} />
            <Route path="/case-study/do-business-in-africa-without-an-african-bank-account" element={<CaseStudyDoBusiness />} />
            <Route path="/case-study/protect-your-wealth-from-local-currency-devaluation-by-using-stablecoins" element={<CaseStudyHelpOthers />} />
            <Route path="/case-study/accept-crypto-as-a-payment-method-without-knowing-anything-about-crypto" element={<CaseStudyEnableBusiness />} />
          </Routes>
        </section>

        <section className="footer-wrapper">
          <Footer />
        </section>
      </Router>
      <ToastContainer
            position="top-right"
            autoClose={8000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
          />
    </div>
  );
}

export default App;
