import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { linkedInBlue } from "../../common/svg";

const About: FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initialize based on current width

   // Detect if the screen is mobile and update state
   useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update state if screen width is below 768px
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="business-wrapper relative">
      <div className={`business-hero relative flex items-center justify-center pt-[100px] pb-8 ${isMobile ? "px-[24px]" : "px-[130px]"} z-10 w-[100%] -mt-16`}>
        <div className={`about-bg relative bg-white ${isMobile ? "px-[26px] pt-[30px] mt-[20px] h-[525px]" : "px-[56px] pt-[48px] mt-[80px] h-[405px]"} pb-[22px] w-full ml-1 mx-auto border border-hgray-200 rounded-[24px] overflow-hidden`}>
          <div className={`about-us absolute h-full flex ${isMobile ? "flex-col w-[80%]" : "w-full"} items-center z-20`}>
            <div className={`"flex flex-col justify-start items-start ${isMobile ? "w-full" : "w-[70%]"} h-full`}>
              <p className="text-htext-subdued text-[14.5px] font-ibm font-medium text-start">
                ABOUT SHIGA
              </p>
              <h1 className={`text-htext-main text-start ${isMobile ? "text-[38px] leading-[43px] mt-[35px]" : "text-[62px] leading-[68px] mt-[25px]"} font-satoshi font-black`}>
                We're driving the adoption of blockchain-based tools to everyone
              </h1>
            </div>

            <div className={`flex flex-col items-center justify-center w-[25%] h-full ${isMobile ? "pt-[50px]" : "pt-[100px]"}`}>
              <p className="text-htext-main text-[16.5px] font-ibm font-semibold text-center">
                Shiga
              </p>
              <p className="text-htext-subdued text-[15.5px] font-ibm italic font-normal mt-[5px] text-center">
                noun
              </p>
              <p className="text-htext-main text-[17.5px] font-ibm font-normal mt-[20px] text-center">
                Freedom/access/enter
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="vision relative bg-white">
        <div className={`vision-wrapper ${isMobile ? "px-[24px]" : "px-[148px]"}`}>
          <div className={`vision-cont pt-[56px] ${isMobile ? "pb-[3.8rem]" : "pb-[6.5rem]"}`}>
            <h5 className="text-htext-subdued text-[14.5px] font-ibm font-medium text-start">
              VISION
            </h5>
            <p className={`mt-[22px] text-htext-main font-satoshi font-black text-start -tracking-[0.8px]  ${isMobile ? "w-full leading-[31px] text-[23px]" : "leading-[48px] w-[600px] text-[39px]"}`}>
              Enabling Pan Africans to access blockchain-based financial
              solutions that can be used in the real world, anywhere, anytime.
            </p>
          </div>

          <div className={`backed-cont border-t border-t-hgray-200 flex ${isMobile ? "flex-col pt-[16px] pb-[3.5rem]" : "pt-[38px] pb-[7rem]"} items-start justify-between`}>
            <p className={`text-htext-main ${isMobile ? "text-[29px]" : "text-[39px]"} font-satoshi font-black text-start -tracking-[0.8px] leading-[48px]`}>
              Backed by
            </p>
            <div className={`${isMobile ? "mt-7 w-[85%]" : ""}`}>
              <img
                src="/assets/backersLarge.svg"
                alt="DFS Labs, Circle, Stellar"
              />
            </div>
          </div>

          <div className={`team-cont border-t border-t-hgray-200 flex ${isMobile ? "flex-col pt-[18px] pb-[22rem] w-full" : "pt-[38px] pb-[17rem]"} items-start justify-between`}>
            <p className={`text-htext-main ${isMobile ? "text-[29px]" : "text-[39px]"} font-satoshi font-black text-start -tracking-[0.8px] leading-[48px]`}>
              The team
            </p>

            <div className={`flex flex-col justify-center items-start ${isMobile ? "w-full mt-6" : "w-[354px]"}`}>
              <p className={`text-htext-subdued ${isMobile ? "text-[15px]" : "text-[14.5px]"} font-ibm font-medium text-start`}>
                LEADERSHIP
              </p>
              <div className="flex items-center py-7 w-full">
                <img className={`${isMobile ? "w-[55px]" : ""}`} src="/assets/Abiola.svg" alt="Abiola Shogbeni" />
                <div className="flex flex-col justify-center items-start ml-2">
                  <p className="text-htext-subdued text-[16px] font-satoshi font-normal text-start">
                    CEO & Founder
                  </p>
                  <Link
                    to="https://www.linkedin.com/in/abiola-s-7029313a/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-1.5 hover:scale-90"
                  >
                    <span dangerouslySetInnerHTML={{ __html: linkedInBlue }} />
                  </Link>
                </div>
              </div>

              <div className="flex items-center py-7 w-full border-t border-t-hgray-200">
                <img className={`${isMobile ? "w-[55px]" : ""}`} src="/assets/Dami.svg" alt="Dami Etomi" />
                <div className="flex flex-col justify-center items-start ml-2">
                  <p className="text-htext-subdued text-[16px] font-satoshi font-normal text-start">
                    COO & Co-founder
                  </p>
                  <Link
                    to="https://www.linkedin.com/in/dami-etomi/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-1.5 hover:scale-90"
                  >
                    <span dangerouslySetInnerHTML={{ __html: linkedInBlue }} />
                  </Link>
                </div>
              </div>

              <p className={`text-htext-subdued ${isMobile ? "text-[15px]" : "text-[14.5px]"} font-ibm font-medium text-start mt-[1.9rem]`}>
                TEAM MATES
              </p>
              <p className={`text-htext-main ${isMobile ? "text-[18px]" : "text-[17.5px]"} font-satoshi font-black text-start mt-[1rem] -tracking-[0.48px]`}>
                Our team has proven ability to build businesses from scratch
                along with the right mix of Fintech, Banking and Decentralised Finance
                experience, ideal to bridge the old and new worlds.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
