import { FC, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { instagram, instagramMob, linkedIn, linkedInMob, twitter, twitterMob } from "../svg";

const Footer: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initialize based on current width

    // Detect if the screen is mobile and update state
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Update state if screen width is below 768px
      };
  
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);


  const isWallet = location.pathname === "/portal-in-and-out";
  const isComing = location.pathname === "/coming-soon";
  const isPayment =
    location.pathname === "/shiga-otc-tailored-for-high-volume-trades";

  return (
    <div className="footer relative w-full bg-h-bg">
      <div className={`footer-cont w-full ${isComing ? "pt-[48px]" : "pt-[240px]"} pb-[48px] ${isMobile ? "px-[24px]" : "px-[148px]"}`}>
        {!isComing && (
        <div className={`preFooter w-[80%]  ${isMobile 
      ? (isWallet || isPayment) 
        ? "h-[490px] -top-[20.5rem]" 
        : "h-[450px] -top-[18rem]" 
      : "h-[350px] -top-[11rem]"
    } bg-hbrand-600 rounded-lg absolute left-0 right-0 mx-auto flex flex-col items-center justify-center`}>
          <div className="preFooter-bg w-full h-full relative z-10">
            <div className="w-full absolute py-[50px] px-3.5 z-20 flex flex-col items-center justify-center">
              <h4
                className={`font-satoshi font-black text-white text-center ${isMobile ? "w-full text-[28px] mt-2.5 leading-[2.4rem]" : "text-[36px] mt-6 leading-[2.8rem]"} 
    ${!isMobile && (isWallet || isPayment) ? "w-[850px]" : "w-[800px]"}`}
              >
                {isWallet
                  ? "Join thousands of users who trust Shiga for their stablecoin and fiat conversions. Get started today and enjoy fast, secure, and easy swaps."
                  : isPayment
                  ? "Join businesses around the world who trust Shiga for their large-scale crypto and fiat conversions."
                  : "Start making payments, swaps, and deposits effortlessly using blockchain technology."}
              </h4>
              <button
                onClick={() => navigate('/coming-soon')}
                className={`${
                  isWallet || isPayment ? "mt-7" : "mt-11"
                } font-ibm font-semibold text-[15.5px] text-white bg-htext-main hover:bg-htext-main/95 hover:text-hgray-400 py-4 ${isMobile ? "px-[45px]" : "px-[85px]"} shadow-s-light rounded-lg border border-hbrand-600`}
              >
                Get started
              </button>
            </div>
          </div>
        </div>
        )}

        <div className="mainFooter w-full py-1 ">
          <div className={`mainFooter-top w-full pb-[64px] flex ${isMobile ? "flex-col  justify-center" : "justify-between"} items-start`}>
            <div className={`flex ${isMobile ? "w-full" : "w-[160px]"} flex-col items-start justify-center`}>
              <img
                className="w-[118px]"
                src="/shigaLogoBlue.svg"
                alt="Shiga logo in blue"
              />

              <p className="footer-texts mt-5 break-words text-[13.5px] text-hgray-400 font-ibm font-normal leading-[23px]">
                Copyright 2024, Shiga. All rights reserved.
              </p>

              <div className={`mt-5 flex items-center w-full ${isMobile ? "h-[20px] gap-x-[12.5px]" : "gap-x-[10px]"}`}>
                <Link
                  to="https://www.linkedin.com/company/shigadigital/?viewAsMember=true"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:scale-90"
                >
                  <span dangerouslySetInnerHTML={{ __html: isMobile ? linkedInMob : linkedIn  }} />
                </Link>

                <Link
                  to="https://x.com/ShigaDigital"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:scale-90"
                >
                  <span dangerouslySetInnerHTML={{ __html: isMobile ? twitterMob : twitter }} />
                </Link>

                <Link
                  to="https://www.instagram.com/shigadigital?igsh=MXdqMm45NDdxd3d0bQ%3D%3D&utm_source=qr"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:scale-90"
                >
                  <span dangerouslySetInnerHTML={{ __html: isMobile ? instagramMob : instagram }} />
                </Link>
              </div>
            </div>

            <div className={`footer-links flex items-center gap-x-[70px] ${isMobile ? "mt-11 w-full" : ""}`}>
              <div className="flex flex-col justify-center items-start">
                <h5 className="footer-text-top text-hgray-400 text-[11.7px] font-ibm font-medium">
                  LEGAL
                </h5>
                <p className={`footer-texts ${isMobile ? "mt-[26px]" : "mt-[22.5px]"} text-white text-[13.5px] font-ibm font-medium hover:text-hgray-200 cursor-pointer`}>
                  Terms of use
                </p>
                <p className={`footer-texts ${isMobile ? "mt-[16px]" : "mt-[13.5px]"} text-white text-[13.5px] font-ibm font-medium hover:text-hgray-200 cursor-pointer`}>
                  Terms & Conditions
                </p>
                <p className={`footer-texts ${isMobile ? "mt-[16px]" : "mt-[13.5px]"} text-white text-[13.5px] font-ibm font-medium hover:text-hgray-200 cursor-pointer`}>
                  Privacy Policy
                </p>
              </div>

              <div className="flex flex-col justify-center items-start">
                <h5 className="footer-text-top text-hgray-400 text-[11.7px] font-ibm font-medium">
                  COMPANY
                </h5>
                <Link to="/our-case-studies">
                  <p className={`footer-texts ${isMobile ? "mt-[26px]" : "mt-[22.5px]"} text-white text-[13.5px] font-ibm font-medium hover:text-hgray-200 cursor-pointer`}>
                    Use cases
                  </p>
                </Link>
                <Link to="/about-us">
                  <p className={`footer-texts ${isMobile ? "mt-[16px]" : "mt-[13.5px]"} text-white text-[13.5px] font-ibm font-medium hover:text-hgray-200 cursor-pointer`}>
                    About us
                  </p>
                </Link>
                <Link to="/contact-support">
                  <p className={`footer-texts ${isMobile ? "mt-[16px]" : "mt-[13.5px]"} text-white text-[13.5px] font-ibm font-medium hover:text-hgray-200 cursor-pointer`}>
                    Contact support
                  </p>
                </Link>
              </div>
            </div>
          </div>

          <div className="mainFooter-bottom w-full border-t border-t-hgray-800 pt-[18px] ">
            <p className="text-[11.7px] text-hgray-400 font-ibm font-normal">
              Shiga Digital Inc is a US-based FINTECH group that specialises in
              developing cutting-edge applications using blockchain and cloud
              technology. Shiga Digital offers its products and services in
              partnership with licensed transmitters in their respective
              jurisdictions. All trademarks and brand names belong to their
              respective owners. Use of these trademarks and brand names do not
              represent endorsement by or association with Shiga Digital Incl.{" "}
            </p>
            <p className="mt-4 text-[11.7px] text-hgray-400 font-ibm font-normal">
              IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT:
              By creating your account with Shiga Digital Inc., you agree to our
              terms and conditions and our partners' terms, to all applicable
              laws and regulations, and to being responsible for compliance with
              any and all applicable local laws.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
